import React from 'react'
import Helmet from 'react-helmet'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

import '../styles/templates/Contact.css'

export default function Template({ data }) {
  let {
    allPrismicContact: {
      edges: [contact],
    },
  } = data
  contact = contact.node
  return (
    <Layout>
      <SEO
        title="CONTANGO - Contact"
        keywords={['Contango Journal', 'Political Theory']}
      />
      <Helmet title="CONTANGO - Contact" />
      <div className="contact">
        <h1>{contact.data.contact_title.text}</h1>
        <div
          className="blog-issue-content"
          dangerouslySetInnerHTML={{
            __html: contact.data.contact_details.html,
          }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrismicContact {
    allPrismicContact {
      edges {
        node {
          id
          data {
            contact_title {
              text
              html
            }
            contact_details {
              text
              html
            }
            contact_coordinates {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`
